<template>
  <div id="myapp">
    <Header active_tab="mentorship"></Header>
    <div
      class="box container"
      v-for="(item, index) in notificationList"
      :key="index"
    >
      <div class="row mt-3 pt-3 justify-content-center">
        <div class="col d-flex justify-content-center">
          <h6>{{ item["message"] }}</h6>
        </div>
      </div>
      <div class="d-flex justify-content-center date-time">
        {{ getDate(item["fileName"]) }}
      </div>
      <div class="row my-3 py-3 justify-content-center">
        <button class="btn btn-light" @click="reviewPaperPage(index)">
          Review
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import db from "@/firebase";
import store from "@/store";

export default {
  name: "EssayUploadNotifcation",
  components: { Header },
  mounted() {
    db.collection("essay_upload_notification")
      .orderBy("fileName")
      .get()
      .then(snapshot => {
        snapshot.forEach(data => {
          console.log(data.data());
          this.notificationList.push(data.data());
        });
      });

    store.state.loading = false;
  },
  data() {
    return {
      notificationList: []
    };
  },
  methods: {
    getDate(data) {
      var date = new Date(data);
      return date.toUTCString();
    },
    reviewPaperPage(index) {
      this.$router.push({
        name: "Essay",
        query: this.notificationList[index]
      });
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,500,700");

#myapp {
  background: linear-gradient(
    150deg,
    rgba(46, 46, 46, 1) 0%,
    rgba(140, 215, 142, 1) 21%,
    rgba(232, 233, 163, 1) 100%
  );
  position: absolute;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.box {
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
  background: linear-gradient(170deg, #b4ec51 0%, #429321 100%);
}

.box h6 {
  color: white;
  font-family: "Oswald", sans-serif;
}

.date-time {
  color: rgb(198, 207, 240);
}

.btn {
  background: greenyellow;
  border-radius: 25px;
  padding: 15px;
  /* color: red; */
  font-weight: 400;
}
</style>
